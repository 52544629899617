import "moment/locale/pt-br";
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import UserService from './services/UserService'
import paths from './router/paths'
import LoadingContainer from './components/LoadingContainer.vue'
import EmptyState from './components/EmptyState.vue'
import Container from './components/Container.vue'
import SearchBar from './components/SearchBar.vue'
import TextInput from './components/FormComponents/TextInput.vue'
import SelectInput from './components/FormComponents/SelectInput.vue'
import RadioInput from './components/FormComponents/RadioInput.vue'
import TextAreaInput from './components/FormComponents/TextAreaInput.vue'
import PasswordInput from './components/FormComponents/PasswordInput.vue'
import FormButton from './components/FormComponents/FormButton.vue'
import TableHead from './components/TableHead.vue'
import ModalComponent from './components/ModalComponent.vue'
import CopyText from './components/CopyText.vue'
import Header from './components/Header.vue'
import ConfirmationAlert from './components/ConfirmationAlert/ConfirmationAlert.vue'
import AutocompleteInput from './components/FormComponents/AutocompleteInput/AutocompleteInput.vue'
import MoneyInput from './components/FormComponents/MoneyInput.vue'
import CalendarComponent from './components/CalendarComponent/CalendarComponent.vue'
import '@/assets/theme/theme.scss'
import 'bootstrap'
import AppointmentService from './services/AppointmentService'
import Navbar from './components/Navbar.vue'
import BottomActionsContainer from './components/BottomActionsContainer.vue'
import PhIcons from './components/PhIcons.vue'
import './assets/js/jquery.js'
import '@/utils/Prototypes/prototypeFunctions.js'
import PaymentService from "./services/PaymentService";
import { EventBus } from "./utils/EventBus";
import RescheduleService from "./services/RescheduleService";
import PaymentMethodList from '@/views/More/PaymentMethodList/PaymentMethodList.vue';
import FeedbackDetail from "./views/FeedbackDetail/FeedbackDetail.vue";
import StarRating from '@/components/StarRating.vue'
import money from 'v-money3'


const pinia = createPinia()
const app = createApp(App);

app.config.globalProperties.$userService = new UserService()
app.config.globalProperties.$paymentService = new PaymentService()
app.config.globalProperties.$appointmentService = new AppointmentService()
app.config.globalProperties.$rescheduleService = new RescheduleService()
app.config.globalProperties.$paths = paths;
app.config.globalProperties.$eventBus = EventBus;
app.config.globalProperties.$baseImgUrl = process.env.VUE_APP_BASE_IMG_URL;
app.config.globalProperties.$appString = process.env.VUE_APP_VERSION_STRING;
// app.config.globalProperties.$formattedFirstAppointmentPrice = Number(process.env.VUE_APP_FIRST_APPOINTMENT_PRICE).toCurrencyString();
app.config.globalProperties.$formattedImediateAppointmentPrice = Number(process.env.VUE_APP_IMEDIATE_APPOINTMENT_PRICE).toCurrencyString();



app.component("Navbar", Navbar);
app.component("LoadingContainer", LoadingContainer);
app.component("EmptyState", EmptyState);
app.component("Container", Container);
app.component("MoneyInput", MoneyInput);
app.component("SearchBar", SearchBar);
app.component("TextInput", TextInput);
app.component("RadioInput", RadioInput);
app.component("SelectInput", SelectInput);
app.component("TextAreaInput", TextAreaInput);
app.component("PasswordInput", PasswordInput);
app.component("FormButton", FormButton);
app.component("TableHead", TableHead);
app.component("ModalComponent", ModalComponent);
app.component("Header", Header);
app.component("ConfirmationAlert", ConfirmationAlert);
app.component("AutocompleteInput", AutocompleteInput);
app.component("PhIcons", PhIcons);
app.component("BottomActionsContainer", BottomActionsContainer);
app.component("CopyText", CopyText);
app.component("CalendarComponent", CalendarComponent);
app.component("PaymentMethodList", PaymentMethodList);
app.component("FeedbackDetail", FeedbackDetail);
app.component("StarRating", StarRating);


app.use(pinia).use(router).use(money).mount('#app')
