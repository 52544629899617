<template>
  <nav class="navbar navbar-expand navbar-light d-none d-md-flex px-4 ps-0" id="topbar">
    <div class="container-fluid">
      <nav aria-label="breadcrumb" class="me-auto ms-4">
        <router-link :to="$paths.home">
          <img src="@/assets/img/navlogo.png" class="rounded" alt=""> <small
            class="text-muted">{{ $appString }}</small>
        </router-link>
      </nav>
      <div class="navbar-user" v-if="userSession">
        <ul class="navbar-nav">
          <router-link active-class="router-link-active" :to="$paths.appointments.list">
            <li class="nav-item">
              <a class="nav-link text-paragraph">Minhas Sessões</a>
            </li>
          </router-link>
          <router-link active-class="router-link-active" :to="$paths.more">
            <li class="nav-item">
              <a class="nav-link text-paragraph">Mais</a>
            </li>
          </router-link>
        </ul>
        <span class="ps-4 pe-3 text-paragraph text-muted">|</span>
        <div class="dropdown cursor-pointer align-items-center d-flex" @mouseover="avatarOver"
          @mouseleave="avatarLeave">
          <span class="me-3 fw-bold text-primary">{{ userSession?.nickname }}</span>
          <a href="#" class="avatar avatar-sm dropdown-toggle">
            <div
              class="avatar-img rounded-circle bg-secondary align-items-center avatar-img bg-secondary d-flex display-6 justify-content-center rounded-circle text-white">
              <img class="w-100" :src="userSession?.formatted_image" alt="">
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-end cursor-pointer">
            <span @click="logout" class="dropdown-item text-danger">Sair</span>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { useMainStore } from '@/store'
import $ from 'jquery'
import { storeToRefs } from 'pinia';
export default {
  name: 'Navbar',
  props: {
    link: String,
    linkName: String,
    sublink: String,
    sublinkName: String,
    current: String
  },
  setup() {
    const store = useMainStore()
    const { session } = storeToRefs(store)
    return {
      userSession: session
    }
  },
  methods: {
    async logout() {
      this.$router.push(this.$paths.login)
      // try {
      //   await this.$userService.logout()
      // } catch (error) {
      //   console.log('ERRO NO LOGOUT', error)
      // } finally {
      // }
    },
    avatarOver() {
      $('.dropdown-menu').addClass('show')
      $('.dropdown-menu').attr('data-bs-popper', true)
    },
    avatarLeave() {
      $('.dropdown-menu').removeClass('show')
      $('.dropdown-menu').removeAttr('data-bs-popper')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
